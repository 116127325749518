.Root {
	display: flex;
	flex-grow: 1;
	overflow-y: hidden;
}
.Container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
}
.Window {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
}
.FlexCenter {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
}
.SidebarContainer {
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	background-color: #f0f0f0;
	padding: 16px;
}
.Sidebar {
	position: sticky;
}
.Topbar {
	display: flex;
	align-items: center;
	padding: 16px;
	background-color: #f0f0f0;
}
.Topbar > *:not(:last-child) {
	margin-right: 10px;
}
.CenterButton {
	display: flex;
	justify-content: center;
}
.DisabledRow {
	color: #cdcdcd;
}
.DefaultRow {
	background-color: #dcf8e5;
}
.DefaultRow:global(:hover) {
	background-color: #b9f7cd;
}
.DefaultRow:global(:hover td) {
	background-color: unset !important;
}
.DeletedRow {
	background-color: #f8dddd;
}
.DeletedRow:global(:hover) {
	background-color: #f8bbba;
}
.DeletedRow:global(:hover td) {
	background-color: unset !important;
}
.Info :global(tr) {
	padding-bottom: 2px;
	padding-top: 2px;
}
.Info :global(td:nth-child(1)) {
	padding-right: 10px;
	font-weight: bold;
}
.Info :global(td:nth-child(2)) {
	text-align: right;
}
.Transfer {
	display: flex;
}
.Transfer :global(.ant-transfer-list) {
	flex-grow: 1;
}
.Calendar {
	padding: 16px;
}