.Container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
}
.Centered {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
}
