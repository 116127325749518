.Root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	flex-grow: 1;
}
.Label {
	margin-bottom: 0 !important;
	white-space: nowrap;
	margin-right: 10px;
}
.Button {
	height: 90px !important;
	font-size: 1.75vw !important;
	line-height: 0px !important;
	background-color: #efefef;
}
.SampleWeight {
	max-width: 300px;
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.Columns {
	display: flex;
	flex: 1;
	margin-top: 16px;
}
.Column {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 100px;
}
.Column:not(:last-of-type) {
	margin-right: 8px;
}
.Column > button {
	margin-bottom: 8px;
}
