.Container {
	margin-bottom: 10px;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.CenterButton {
	display: flex;
	justify-content: center;
}
.ErrorMessage {
	text-align: center;
	padding: 8px;
}
