.App {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.Body {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Login {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-top: 32px;
}
.Version {
	margin-top: 10px;
	text-align: center;
	font-size: 1.25rem;
}
