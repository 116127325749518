.Container {
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Main {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 16px;
}
.Centered {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
}