.Footer {
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: stretch;
	padding: 0.5em;
	flex-shrink: 0;
	z-index: 999;
	border-top: 1px solid rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	background: #fff;
}

.Button {
	flex-shrink: 1;
	min-height: 90px;
	font-size: 32px !important;
	height: auto;
	line-height: normal;
	white-space: normal;
	display: flex;
	width: 100%;
	overflow: hidden;
}

.Button:global(:not(:last-child)) {
	margin-right: 10px;
}
