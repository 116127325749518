.Root {
	margin-top: 16px;
}
.Buttons {
	display: flex;
	flex-direction: column;
}
.Buttons :global(.button) {
	min-height: 100px;
	margin-bottom: 5px;
}
.Horizontal {
	display: flex;
	align-items: center;
}
.Horizontal:not(:last-child) {
	margin-bottom: 8px;
}
.Horizontal > :global(label.label) {
	margin-right: 1.5rem;
	margin-bottom: 0;
	text-align: right;
}
.Horizontal > * {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
}
