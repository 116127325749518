#root {
	display: flex;
	flex-direction: column;
	background-color: #f0f0f0;
}

.columns:last-child {
	margin-bottom: 0 !important;
}
.ReactVirtualized__Grid.ReactVirtualized__List,
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
	outline: none;
}
.ReactVirtualized__Table__row:hover {
	background-color: #eee;
}
.modal-card-foot {
	justify-content: flex-end;
	padding-top: 16px;
	padding-bottom: 16px;
}
.modal {
	z-index: 2000;
}
.ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title {
	padding: 8px 12px;
	font-size: 16px;
	min-width: 150px;
	border-top: 1px solid #f0f0f0;
}
.ant-dropdown-menu-item:first-child,
.ant-dropdown-menu-item:first-child > a,
.ant-dropdown-menu-submenu-title:first-child > a {
	border-top: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
	height: 116px;
}
.ant-picker-calendar-date-content li {
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.button.button-circle {
	width: 34px;
	height: 34px;
	border-radius: 100%;
	text-align: center;
	font-size: 18px;
}
.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: hidden;
}
.ant-table {
	flex-grow: 1;
	overflow-y: auto;
	background-color: #fff;
}
.ant-table thead > tr > th,
.ant-table tbody > tr > td {
	vertical-align: middle;
}
.ant-table-pagination.ant-pagination {
	background: #f0f0f0;
	padding: 16px;
	margin: 0 !important;
}
.ant-tabs-top > .ant-tabs-nav {
	margin-bottom: 8px !important;
}
.ant-tabs-content-holder {
	display: flex;
}
.ant-tabs-content .ant-tabs-tabpane-active {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
}
.ant-tabs-nav .ant-tabs-tab {
	font-size: 1.1rem;
}
.ant-tabs-nav-wrap {
	margin: 0 16px;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
	background: inherit;
	transition: inherit;
}
