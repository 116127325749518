.Root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.TallButton {
	min-height: 90px;
}
.Info {
	font-size: 1.25rem;
	margin-top: 16px;
}
.Table {
	width: 100%;
}
.ConfirmButton {
	margin-top: 16px;
	justify-content: end;
}
.Columns {
	display: flex;
	flex: 1;
}
.Column {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 100px;
}
.Column:not(:last-of-type) {
	margin-right: 8px;
}
.Column > button {
	margin-bottom: 8px;
}
