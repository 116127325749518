.Container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}
.EvenRow {
	border-bottom: 1px solid #e0e0e0;
}
.OddRow {
	border-bottom: 1px solid #e0e0e0;
	background-color: #fafafa;
}
.HeaderRow {
	border-bottom: 2px solid #e0e0e0;
}
.CompletedRow:global(:hover td),
.UnsentRow:global(:hover td) {
	background-color: unset !important;
}
.CompletedRow {
	background-color: #dcf8e5;
}
.CompletedRow:global(:hover) {
	background-color: #b9f7cd;
}
.UnsentRow {
	background-color: #fff3c9;
}
.UnsentRow:global(:hover) {
	background-color: #ffeeb1;
}
.Topbar {
	padding: 16px;
}
