.Container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Container :global(label.label) {
	font-size: 1.25rem;
}
.Container :global(.input) {
	font-size: 1.25rem;
}
.Body {
	margin-top: 16px;
}
.Row {
	display: flex;
	margin-bottom: 0.75rem;
}
.RowItem {
	flex-grow: 1;
	flex-basis: 0;
}
.RowItem:not(:last-child) {
	margin-right: 16px;
}
.Grow {
	flex-grow: 1;
}
