.Column {
	display: block;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 100px;
}
.Column :global(::-webkit-scrollbar) {
	display: none;
}
.Button {
	font-size: 1.75vw !important;
	line-height: 0px !important;
	background-color: #efefef;
}
