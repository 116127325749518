.mdl-textfield {
	position: relative;
	font-size: 16px;
	display: inline-block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 100%;
	margin: 0;
	padding: 20px 0;
	width: 100%;
}
.mdl-textfield__input {
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	display: block;
	font-size: 16px;
	font-family: Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 4px 0;
	width: 100%;
	background: 0 0;
	text-align: left;
	color: inherit;
}
.mdl-textfield__input[type='number'] {
	-moz-appearance: textfield;
}
.mdl-textfield__input[type='number']::-webkit-inner-spin-button,
.mdl-textfield__input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.mdl-textfield.is-focused .mdl-textfield__input {
	outline: 0;
}
.mdl-textfield.is-invalid .mdl-textfield__input {
	border-color: #d50000;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.mdl-textfield__label {
	bottom: 0;
	color: rgba(0, 0, 0, 0.26);
	font-size: 16px;
	left: 0;
	right: 0;
	pointer-events: none;
	position: absolute;
	display: block;
	top: 24px;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-align: left;
	-webkit-transition-duration: 0.2s;
	-o-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
	color: #d50000;
	font-size: 12px;
}
.mdl-textfield__label:after {
	background-color: hsl(204, 86%, 53%); /*#3f51b5;*/
	bottom: 20px;
	content: '';
	height: 2px;
	left: 45%;
	position: absolute;
	-webkit-transition-duration: 0.2s;
	-o-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	visibility: hidden;
	width: 10px;
}
.mdl-textfield.is-focused .mdl-textfield__label:after {
	left: 0;
	visibility: visible;
	width: 100%;
}
.mdl-textfield.is-invalid .mdl-textfield__label:after {
	background-color: #d50000;
}
.mdl-textfield__error {
	color: #d50000;
	position: absolute;
	font-size: 12px;
	margin-top: 3px;
	visibility: hidden;
	display: block;
}
.mdl-textfield.is-invalid .mdl-textfield__error {
	visibility: visible;
}
.firebaseui-container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	font: 16px Roboto, arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	min-height: 200px;
	overflow: hidden;
	z-index: 1;
	position: relative;
	background: #fff;
	border-radius: 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	color: rgba(0, 0, 0, 0.87);
	direction: ltr;
	margin: 0 auto;
	max-width: 360px;
	text-align: left;
	width: 100%;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.firebaseui-card-header {
	padding: 24px 24px 0 24px;
}
.firebaseui-card-content {
	padding: 0 24px;
}
.firebaseui-card-footer {
	padding: 0 24px;
}
.firebaseui-card-actions {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: table;
	font-size: 14px;
	padding: 8px 24px 24px 24px;
	text-align: left;
	width: 100%;
}
.firebaseui-form-links {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}
.firebaseui-form-actions {
	display: table-cell;
	text-align: right;
	white-space: nowrap;
	width: 100%;
}
.firebaseui-title {
	color: rgba(0, 0, 0, 0.87);
	direction: ltr;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	margin: 0;
	padding-bottom: 20px;
	text-align: left;
}
.firebaseui-text {
	color: rgba(0, 0, 0, 0.87);
	direction: ltr;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
}
.firebaseui-text-emphasis {
	font-weight: 700;
}
.firebaseui-error {
	color: #dd2c00;
	direction: ltr;
	font-size: 12px;
	line-height: 16px;
	margin: -16px 0 16px;
	text-align: left;
}
.firebaseui-error-wrapper {
	min-height: 16px;
}
.firebaseui-hidden {
	display: none;
}
.firebaseui-label {
	color: rgba(0, 0, 0, 0.54);
	direction: ltr;
	font-size: 14px;
	text-align: left;
}
.firebaseui-input,
.firebaseui-input-invalid {
	border-radius: 0;
	color: rgba(0, 0, 0, 0.87);
	direction: ltr;
	font-size: 16px;
	width: 100%;
}
input.firebaseui-input,
input.firebaseui-input-invalid {
	direction: ltr;
	text-align: left;
}
.firebaseui-input-invalid {
	border-color: #dd2c00;
}
.mdl-textfield .firebaseui-input {
	border-color: rgba(0, 0, 0, 0.12);
}
.mdl-textfield .firebaseui-label::after {
	background-color: hsl(204, 86%, 53%); /*#3f51b5;*/
}
.firebaseui-textfield-invalid.mdl-textfield .firebaseui-input {
	border-color: #dd2c00;
}
.firebaseui-textfield-invalid.mdl-textfield .firebaseui-label::after {
	background-color: #dd2c00;
}
.firebaseui-button {
	display: inline-block;
	height: 36px;
	margin-left: 8px;
	min-width: 88px;
}
.firebaseui-link {
	color: #4285f4;
	font-variant: normal;
	font-weight: 400;
	text-decoration: none;
}
.firebaseui-link:hover {
	text-decoration: underline;
}
.firebaseui-form-actions div {
	float: left;
}
.mdl-textfield__label {
	font-weight: 400;
	margin-bottom: 0;
}
.firebaseui-header-logo {
	display: block;
	margin: 10px auto 20px auto;
}
.mdl-textfield__label {
	color: #757575;
	font-size: 12px;
	top: 4px;
	visibility: visible;
}
@media (max-width: 480px) {
	.firebaseui-container {
		-webkit-box-shadow: none;
		box-shadow: none;
		max-width: none;
		width: 100%;
	}
	.firebaseui-card-header {
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 16px;
		padding: 16px 24px 0 24px;
	}
	.firebaseui-title {
		padding-bottom: 16px;
	}
	.firebaseui-card-actions {
		padding-right: 24px;
	}
}
