.Container {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.TabPane {
	background-color: #fff;
}
.OddRow {
	background-color: #fafafa;
}
.HeaderRow {
	background-color: #f0f0f0;
	border-bottom: 2px solid #e0e0e0;
}
.AverageRow {
	border-bottom: 2px solid #ccc;
	background-color: #f0f0f0;
}
.AveragesRow {
	border-top: 2px solid #e0e0e0;
	background-color: #f0f0f0;
	font-weight: 700;
}
.AlignRight {
	text-align: right;
}
.AlignCenter {
	text-align: center;
}
.Tag {
	align-items: center;
	border-radius: 5px;
	padding: 0 3px;
	color: #4a4a4a;
	display: inline-flex;
	justify-content: center;
	line-height: 1.5;
	white-space: nowrap;
	background-color: #ffdd57;
	color: rgba(0, 0, 0, 0.7);
}
.Averages {
	font-size: 1.25rem;
	flex-grow: 0;
	flex-shrink: 1;
}
