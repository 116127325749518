.Root {
	font-size: 1rem;
	position: relative;
	text-align: left;
	width: 100%;
}
.Input {
	padding-right: 2.25em !important;
}
.ClearIcon {
	position: absolute;
	cursor: pointer;
	color: #ccc;
	transition: color 0.3s;
	right: 0;
	height: 2em;
	width: 2em;
	top: 0;
	z-index: 4;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	font-size: 1.25rem;
}
.ClearIcon:global(:hover) {
	color: #999;
}
.ClearIcon:global(:active) {
	color: #666;
}
