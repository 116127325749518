.Header {
	display: flex;
	justify-content: space-between;
}
.Panel :global(.ant-list-item-meta) {
	flex: 0;
}
.Panel :global(.ant-list-item-content) {
	justify-content: flex-start;
}
