.Container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Columns {
	display: flex;
	flex: 1;
}
.Button {
	font-size: 1.75vw !important;
	min-height: 100px;
}
.Button:not(:last-of-type) {
	margin-right: 8px;
}
.Spinner:not(:last-of-type) {
	padding-right: 8px;
}
