.Container {
	flex-grow: 1;
}
.Container :global(label.label) {
	font-size: 1.25rem;
}
.Container :global(.rw-widget) {
	font-size: 1.25rem;
}
.Container :global(.rw-input) {
	color: black;
}
.Container :global(.button) {
	min-height: 90px;
}
.Container :global(.rw-widget-picker) {
	height: 2.25em;
}
.Columns {
	display: flex;
	flex: 1;
}
.Columns:not(:last-of-type) {
	margin-bottom: 16px;
}
.Column {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 100px;
}
.Column:not(:last-of-type) {
	margin-right: 8px;
}
.Subtitle {
	font-size: 1.25rem;
}
