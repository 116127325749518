.Root {
	margin-top: 16px;
}
.Columns {
	display: flex;
	flex: 1;
}
.Column {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	min-width: 100px;
}
.Column:not(:last-of-type) {
	margin-right: 16px;
}
.Numpad {
	flex-grow: 0;
}
.Flex {
	flex-grow: 1;
}